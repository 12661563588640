export const noticeApi = {
  getList: { // 获取session-id
    method: 'post',
    api: 'user/notice/getList'
  },
  editnotice: { // 获取session-id
    method: 'post',
    api: 'user/notice/editnotice'
  },
  savenotice: { // 获取session-id
    method: 'post',
    api: 'user/notice/savenotice'
  },
  delnotice: { // 获取session-id
    method: 'post',
    api: 'user/notice/del'
  },
}