/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '统计1',
         path: "/",
         redirect: '/home/index/index',
     },
     {
         name: '统计',
         path: '/home',
         perm: '',
         meta: {module: 'home'},
         component: Layout,
         children: [
             {
                 name: '统计',
                 path: '/home/index/index',
                 perm: '',
                 meta: {module: 'home'},
                 show:false,
                 component: () => import('@/views/home/index/index.vue'),
             },
             {
                name: '修改密码',
                path: '/home/changePwd',
                perm: '',
                meta: {module: 'home'},
                show:false,
                component: () => import('@/views/home/index/changePwd.vue'),
            },
         ]
     }
 ]