import $api from '@/api'
import $store from '@/store'
export default {
    namespaced: true,
    state: {
        isLogin: null
    },
    mutations: {
        setLoginStatus(state, isLogin) {
            state.isLogin = isLogin
        },
        setUserInfo(state, user) {
            state.user = user
        },
    },
    actions: {
        getLoginStatus({ state, commit }) { // 获取登录状态
            return new Promise((resolve) => {
                if (state.isLogin != null) {
                    resolve(state.isLogin)
                    return
                }
                $api.aloneApi.getLoginStatus().then(res => {
                    if (res.code === 0) {
                        commit('setLoginStatus', res.data.loginStatus)
                        resolve(res.data.loginStatus)
                    }else{
                        resolve(false)
                    }
                })
            })
        },
        initUser({commit}){  // 清空用户信息
            commit('setLoginStatus', false)
            commit('setUserInfo', {})
            localStorage.setItem('sessionId','')
            $store.commit('account/setSessionId','')
            $store.commit('config/setUserInfo', {})
        },
        loginOut({ dispatch }) {
            return new Promise((resolve) => {
                $api.aloneApi.logout().then(res => {
                    if (res.code == 0) {
                        dispatch('initUser')
                        resolve()
                    }
                })
            })
        }
    },
    getters: {
        setting: state => state.setting,
        registSetting: state => state.registSetting
    }

}