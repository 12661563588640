export const shopApi = {
  // 初始化信息
  getInit: {
    method: 'get',
    api: 'user/shop/init',
  },
  getStatistics: {
    method: 'get',
    api: 'user/shop/getStatistics',
  },
  uploadImg: {
    method: 'post',
    api: 'user/shop/uploadImg',
  },
  changePwd: { // 获取当前登录状态
    method: 'post',
    api: 'user/shop/changePwd'
  },
}