export const memberApi = {
  get: {
    method: 'post',
    api: 'user/member/get'
  },
  getList: {
    method: 'post',
    api: 'user/member/getList'
  },
  del: {
    method: 'post',
    api: 'user/member/del'
  },
  getAllLevel: {
    method: 'post',
    api: 'user/member/getAllLevel'
  },
  getAllIndustry: {
    method: 'post',
    api: 'user/member/getAllIndustry'
  },
  setLevel: {
      method: 'post',
      api: 'user/member/setLevel'
  },
  getLevelList: {
    method: 'post',
    api: 'user/member/getLevelList'
  },
  deleteLevel: {
    method: 'post',
    api: 'user/member/deleteLevel'
  },
  setIndustry: {
    method: 'post',
    api: 'user/member/setIndustry'
  },
  getIndustryList: {
    method: 'post',
    api: 'user/member/getIndustryList'
  },
  deleteIndustry: {
    method: 'post',
    api: 'user/member/deleteIndustry'
  },
  setexamine: {
    method: 'post',
    api: 'user/member/setexamine'
  },
  save: {
    method: 'post',
    api: 'user/member/save'
  },
  getAllBw: {
    method: 'post',
    api: 'user/member/getAllBw'
  },
  setBw: {
    method: 'post',
    api: 'user/member/setBw'
  },
  getBwList: {
    method: 'post',
    api: 'user/member/getBwList'
  },
  deleteBw: {
    method: 'post',
    api: 'user/member/deleteBw'
  },
  getAllJt: {
    method: 'post',
    api: 'user/member/getAllJt'
  },
  setJt: {
    method: 'post',
    api: 'user/member/setJt'
  },
  getJtList: {
    method: 'post',
    api: 'user/member/getJtList'
  },
  deleteJt: {
    method: 'post',
    api: 'user/member/deleteJt'
  },
}