export default {
  title: '统计',
  name: 'home',
  path: '/home/index/index',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 1,
  children: {
      finance: {
          title: '统计',
          name: 'home',
          path: '/home/index/index',
          icon: "icon-zuocedaohang-shouye1",
          perm:'',
          show: ($route) =>
                  $route?.path === "/home/index/index"
      },
      changePwd: {
        title: '修改密码',
        name: 'home',
        path: '/home/changePwd',
        icon: "icon-zuocedaohang-shouye1",
        perm:'',
        show: ($route) =>
                $route?.path === "/home/changePwd"
    }
  }
}
