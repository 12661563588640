export default {
  title: '公告',
  name: 'notice',
  path: '/notice/index',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 4,
  children: {
      diypage: {
          title: '公告',
          name: 'notice',
          path: '/notice/index',
          icon: "icon-zuocedaohang-shouye1",
          perm:'',
          show: ($route) =>
                  $route?.path === "/notice/index"
      }
  }
}
